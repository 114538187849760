<template>
	<el-scrollbar class="seller-scrollbar">
		<el-card class="seller-card">
			<el-breadcrumb slot="header" separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ name: 'campaignIndex' }">{{ $t('seller.campaign.title') }}</el-breadcrumb-item>
				<el-breadcrumb-item>{{ pageInfo.name }}</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="page-head">
				<div class="title">{{ pageInfo.name }}<el-tag type="info">{{ pageInfo.label_name }}</el-tag></div>
				<ul class="text">
					<li>
						<span>{{ $t('seller.campaign.shopSignUpTime') + '：' }}{{ pageInfo.s_enroll_start_time | parseTime('{y}.{m}.{d} {h}:{i}') }}-{{ pageInfo.s_enroll_end_time | parseTime('{y}.{m}.{d} {h}:{i}') }}</span>
						<el-tag type="info" size="mini">{{ onActivityStatus(pageInfo.s_enroll_start_time, pageInfo.s_enroll_end_time) }}</el-tag>
					</li>
					<li>
						<span>{{ $t('seller.campaign.itemSignUpTime') + '：' }}{{ pageInfo.g_enroll_start_time | parseTime('{y}.{m}.{d} {h}:{i}') }}-{{ pageInfo.g_enroll_end_time | parseTime('{y}.{m}.{d} {h}:{i}') }}</span>
						<el-tag type="info" size="mini">{{ onActivityStatus(pageInfo.g_enroll_start_time, pageInfo.g_enroll_end_time) }}</el-tag>
					</li>
					<li><span>{{ $t('seller.campaign.activityTime') + '：' + $t('seller.campaign.warmUpText1') }}{{ onActivityDays(pageInfo.warm_up_start_time,pageInfo.warm_up_end_time) }}{{ $t('seller.campaign.warmUpText2') }}{{ onActivityDays(pageInfo.sales_start_time,pageInfo.sales_end_time) }}{{ $t('seller.campaign.warmUpText3') }}</span></li>
				</ul>
			</div>
			<div class="page-rule">
				<div class="title"><i class="el-icon-success"></i><span>{{ $t('seller.campaign.accordWith') }}</span></div>
				<el-button v-if="pageInfo.is_store_join === 0" type="primary" size="medium" :disabled="pageInfo.s_enroll_start_time > parseInt(Date.now() / 1000) || pageInfo.s_enroll_end_time < parseInt(Date.now() / 1000)" @click="$router.push({ name: 'campaignApply', paramse: {id: pageInfo.activity_id }})">{{ $t('seller.actions.goSignUp') }}</el-button>
			</div>
			<div class="page-mian">
				<el-tabs v-model="activeName" type="card">
					<el-tab-pane :label="$t('seller.campaign.introduce')" name="desc">
						<div class="page-desc">
							<div class="title">{{ pageInfo.name }}</div>
							<div class="text">
								<h4>{{ $t('seller.campaign.activityTime') }}</h4>
								<p>{{ $t('seller.campaign.warmUpText1') }}{{ onActivityDays(pageInfo.warm_up_start_time,pageInfo.warm_up_end_time) }}{{ $t('seller.campaign.warmUpText2') }}{{ onActivityDays(pageInfo.sales_start_time,pageInfo.sales_end_time) }}{{ $t('seller.campaign.warmUpText3') }}</p>
								<h4>{{ $t('seller.campaign.signUpTime') }}</h4>
								<p>{{ pageInfo.s_enroll_start_time | parseTime('{y}.{m}.{d} {h}:{i}') }} 至 {{ pageInfo.s_enroll_end_time | parseTime('{y}.{m}.{d} {h}:{i}') }}</p>
								<h4>{{ $t('seller.campaign.introduce') }}</h4>
							</div>
							<div v-html="pageInfo.content"></div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</el-card>
	</el-scrollbar>
</template>

<script>
import { fetchCampaignInfo } from '@/api/seller/market'
export default {
	data() {
		return {
			pageInfo: {},
			activeName: 'desc'
		}
	},
	created () {
		this.getData()
	},
	methods: {
		async getData() {
			const { data } = await fetchCampaignInfo({ activity_id: this.$route.params.id })
			this.pageInfo = data
		},
		onActivityStatus(startTime,endTime) {
			var currTime = parseInt(Date.now() / 1000)
			if(currTime < startTime) {
				return this.$t('seller.campaign.notStarted')
			} else if (currTime > startTime && currTime < endTime) {
				return this.$t('seller.campaign.inProgress')
			} else if(currTime > endTime) {
				return this.$t('seller.campaign.expired')
			}
		},
		onActivityDays(startTime,endTime) {
			const diff = endTime - startTime
			return Math.ceil(diff / 86400)
		}
	}
}
</script>

<style lang="scss" scoped>
.page-head {
	padding: 18px;
	border-radius: 4px;
	background-color: rgb(249, 249, 249);
	.title {
		color: #111;
		font-size: 24px;
		.el-tag {
			color: #111111;
			margin-left: 8px;
			border-color: transparent;
			background-color: rgba(0, 0, 0, 0.06);
		}
	}
	.text {
		margin-top: 9px;
		li {
			font-size: 12px;
			display: inline-block;
			vertical-align: middle;
			margin-right: 12px;
			span {
				margin-right: 4px;
			}
			.el-tag {
				color: rgb(17, 17, 17);
				border-color: transparent;
				background-color: rgba(0, 0, 0, 0.06);
			}
		}
	}
}
.page-rule {
	padding: 0 18px;
	margin-top: 18px;
	height: 56px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: linear-gradient(90deg, rgba(61, 127, 255, 0.06) 0%, rgba(61, 127, 255, 0.02) 100%);
	.title {
		font-size: 14px;
		color: #111111;
		[class^=el-icon-] {
			color: $--seller-primary;
			margin-right: 12px;
			font-size: 20px;
			vertical-align: middle;
		}
	}
}
.page-mian {
	margin-top: 18px;
}
.page-desc {
	max-width: 900px;
	margin: 0 auto;
	.title {
		font-weight: bold;
		margin-bottom: 12px;
		padding-top: 24px;
		font-size: 18px;
		color: rgb(17, 17, 17);
	}
	.text {
		h4 {
			font-weight: bold;
			margin-bottom: 12px;
			padding-top: 24px;
			font-size: 16px;
			color: rgb(17, 17, 17);
		}
	}
	p {
		line-height: 1.74;
	}
}
</style>